export default defineNuxtRouteMiddleware((to, _from) => {
  const {
    announcePage,
    eventPage,
    boardFreePage,
    boardQnaPage,
    boardIssuePage,
    boardRoutePage,
    boardVehicleCarePage,
    boardRidingPage,
    boardTuningPage,
    boardGalleryPage,
    boardReportTheftPage,
    boardBusinessPage,
    marketOver125ManualPage,
    marketOver125ScooterPage,
    marketUnder125ManualPage,
    marketUnder125ScooterPage,
    marketGearPage,
    marketPartsPage,
    marketDeliveryGearPage,
    marketBuyPage,
    reviewMotorcycleListPage,
  } = storeToRefs(useArticlePage())

  const resetPages = (pages: Ref<number>[]) => {
    pages.forEach(page => page.value = 1)
  }

  const clearArticlePage = () => {
    if (to.path.includes('/announce')) {
      resetPages([
        boardFreePage, boardQnaPage, boardIssuePage, boardRoutePage,
        boardVehicleCarePage, boardRidingPage, boardTuningPage, boardGalleryPage,
        boardReportTheftPage, boardBusinessPage, marketOver125ManualPage,
        marketOver125ScooterPage, marketUnder125ManualPage, marketUnder125ScooterPage,
        marketGearPage, marketPartsPage, marketDeliveryGearPage, marketBuyPage,
      ])
    }
    else if (to.path.includes('/board')) {
      resetPages([
        announcePage, eventPage, marketOver125ManualPage, marketOver125ScooterPage,
        marketUnder125ManualPage, marketUnder125ScooterPage, marketGearPage,
        marketPartsPage, marketDeliveryGearPage, marketBuyPage,
      ])
    }
    else if (to.path.includes('/market')) {
      resetPages([
        announcePage, eventPage, boardFreePage, boardQnaPage, boardIssuePage,
        boardRoutePage, boardVehicleCarePage, boardRidingPage, boardTuningPage,
        boardGalleryPage, boardReportTheftPage, boardBusinessPage,
      ])
    }
    else {
      resetPages([
        announcePage, eventPage, boardFreePage, boardQnaPage, boardIssuePage,
        boardRoutePage, boardVehicleCarePage, boardRidingPage, boardTuningPage,
        boardGalleryPage, boardReportTheftPage, boardBusinessPage, marketOver125ManualPage,
        marketOver125ScooterPage, marketUnder125ManualPage, marketUnder125ScooterPage,
        marketGearPage, marketPartsPage, marketDeliveryGearPage, marketBuyPage,
        reviewMotorcycleListPage,
      ])
    }
  }

  clearArticlePage()
})
